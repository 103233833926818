import { WidgetRegistry } from "@smallstack/widget-core";
import { AddToArrayFormWidget } from "./lib/widgets/add-to-array-form-widget";
import { ArrayFilterWidget } from "./lib/widgets/array-filter-widget";
import { ArraySubsetFormEditorWidget } from "./lib/widgets/array-subset-form-editor-widget";
import { AutoGeolocationInputWidget } from "./lib/widgets/auto-geolocation-input-widget";
import { BooleanFilterWidget } from "./lib/widgets/boolean-filter-widget";
import { BooleanInputWidget } from "./lib/widgets/boolean-input-widget";
import { BreakpointInputWidget } from "./lib/widgets/breakpoint-input-widget";
import { ColorInputWidget } from "./lib/widgets/color-form-input-widget";
import { CoordinatesInputWidget } from "./lib/widgets/coordinates-input-widget";
import { CustomFieldsInputWidget } from "./lib/widgets/custom-fields-input-widget";
import { DateFilterWidget } from "./lib/widgets/date-filter-widget";
import { DateInputWidget } from "./lib/widgets/date-input-widget";
import { DateStringFilterWidget } from "./lib/widgets/date-string-filter-widget";
import { DateStringInputWidget } from "./lib/widgets/date-string-input-widget";
import { DateTimeFilterWidget } from "./lib/widgets/date-time-filter-widget";
import { DateTimeInputWidget } from "./lib/widgets/date-time-input-widget";
import { FileIdInputWidget } from "./lib/widgets/file-id-input-widget";
import { FileUrlInputWidget } from "./lib/widgets/file-url-input-widget";
import { FilterFieldWidget } from "./lib/widgets/filter-field-widget";
import { FormCustomLayoutFieldWidget } from "./lib/widgets/form-custom-layout-field-widget";
import { FormCustomLayoutWidget } from "./lib/widgets/form-custom-layout-widget";
import { FormLayoutWidget } from "./lib/widgets/form-layout-widget";
import { GoogleMapsInputWidget } from "./lib/widgets/google-maps-input-widget";
import { HtmlSizingInputWidget } from "./lib/widgets/html-sizing-input-widget";
import { I18nFilterWidget } from "./lib/widgets/i18n-filter-widget";
import { I18nInputWidget } from "./lib/widgets/i18n-input-widget";
import { IconInputWidget } from "./lib/widgets/icon-form-input";
import { LinksInputWidget } from "./lib/widgets/links-input-widget";
import { MangoQueryInputWidget } from "./lib/widgets/mango-query-input-widget";
import { NumberFilterWidget } from "./lib/widgets/number-filter-widget";
import { NumberInputWidget } from "./lib/widgets/number-input-widget";
import { ObjectFilterWidget } from "./lib/widgets/object-filter-widget";
import { PatchArrayFormWidget } from "./lib/widgets/patch-array-form-widget";
import { SaveModelBtnWidget } from "./lib/widgets/save-model-btn-widget";
import { SelectFilterWidget } from "./lib/widgets/select-filter-widget";
import { SelectInputWidget } from "./lib/widgets/select-input-widget";
import { SimpleSelectInputWidget } from "./lib/widgets/simple-select-input-widget";
import { SocketSaveModelBtnWidget } from "./lib/widgets/socket-save-model-btn-widget";
import { StringFilterWidget } from "./lib/widgets/string-filter-widget";
import { StringInputWidget } from "./lib/widgets/string-input-widget";
import { TextareaInputWidget } from "./lib/widgets/textarea-input-widget";
import { TimeSpanFilterWidget } from "./lib/widgets/time-span-filter-widget";
import { TimeSpanInputWidget } from "./lib/widgets/time-span-input-widget";
import { TypeDescriptorInputWidget } from "./lib/widgets/type-descriptor-widget";
import { WidgetTreeInputWidget } from "./lib/widgets/widget-tree-input-widget";

export function addFormWidgets(widgetRegistry: WidgetRegistry): void {
  widgetRegistry.addWidget(AddToArrayFormWidget);
  widgetRegistry.addWidget(ArrayFilterWidget);
  widgetRegistry.addWidget(AutoGeolocationInputWidget);
  widgetRegistry.addWidget(BooleanFilterWidget);
  widgetRegistry.addWidget(BooleanInputWidget);
  widgetRegistry.addWidget(ColorInputWidget);
  widgetRegistry.addWidget(CoordinatesInputWidget);
  widgetRegistry.addWidget(CustomFieldsInputWidget);
  widgetRegistry.addWidget(DateFilterWidget);
  widgetRegistry.addWidget(DateInputWidget);
  widgetRegistry.addWidget(DateStringFilterWidget);
  widgetRegistry.addWidget(DateStringInputWidget);
  widgetRegistry.addWidget(DateTimeFilterWidget);
  widgetRegistry.addWidget(DateTimeInputWidget);
  widgetRegistry.addWidget(FilterFieldWidget);
  widgetRegistry.addWidget(FileIdInputWidget);
  widgetRegistry.addWidget(FileUrlInputWidget);
  widgetRegistry.addWidget(FormCustomLayoutFieldWidget);
  widgetRegistry.addWidget(FormCustomLayoutWidget);
  widgetRegistry.addWidget(FormLayoutWidget);
  widgetRegistry.addWidget(GoogleMapsInputWidget);
  widgetRegistry.addWidget(HtmlSizingInputWidget);
  widgetRegistry.addWidget(I18nFilterWidget);
  widgetRegistry.addWidget(I18nInputWidget);
  widgetRegistry.addWidget(IconInputWidget);
  widgetRegistry.addWidget(LinksInputWidget);
  widgetRegistry.addWidget(MangoQueryInputWidget);
  widgetRegistry.addWidget(NumberFilterWidget);
  widgetRegistry.addWidget(NumberInputWidget);
  widgetRegistry.addWidget(ObjectFilterWidget);
  widgetRegistry.addWidget(SaveModelBtnWidget);
  widgetRegistry.addWidget(SelectFilterWidget);
  widgetRegistry.addWidget(SelectInputWidget);
  widgetRegistry.addWidget(SimpleSelectInputWidget);
  widgetRegistry.addWidget(SocketSaveModelBtnWidget);
  widgetRegistry.addWidget(StringFilterWidget);
  widgetRegistry.addWidget(StringInputWidget);
  widgetRegistry.addWidget(TimeSpanFilterWidget);
  widgetRegistry.addWidget(TimeSpanInputWidget);
  widgetRegistry.addWidget(TextareaInputWidget);
  widgetRegistry.addWidget(TypeDescriptorInputWidget);
  widgetRegistry.addWidget(WidgetTreeInputWidget);
  widgetRegistry.addWidget(BreakpointInputWidget);
  widgetRegistry.addWidget(PatchArrayFormWidget);
  widgetRegistry.addWidget(ArraySubsetFormEditorWidget);
}
