import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const SystemMessageSenderWidget: WidgetRegistryEntry = {
  name: "SystemMessageSender",
  configuration: {
    templateName: "System Nachrichten Sender",
    templateDescription: "Zeigt ein Formular, welches eine Nachricht an den System Thread sendet.",
    icon: "sent",
    ssr: false,
    dataSchema: {
      type: "object",
      properties: {}
    }
  },
  component: () => import("./system-message-sender-widget.component").then((m) => m.SystemMessageSenderWidgetComponent)
};
